import React from 'react';
import './App.scss';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Projects/>
      <Footer/>     
    </div>
  );
}

export default App;

import React from 'react'
import { MdMailOutline } from "react-icons/md";
// import PropTypes from 'prop-types'
import skylines from './media/ambz.png';
import showRoom from './media/showRoom.png'
import juxxed from './media/storehouse.png';
import contacter from './media/contacter.png';
import h4m from './media/h4m.png';
import gitFinder from './media/gitfind.png';
// import SideBar from '../components/SideBar'

const Projects = () => {
    return (
       <div id='contentWrapper'>
        <section id='projectsContainer'>
            <article className='projects'>
                <div className='combine'>
                <aside className='sideSkills'>
                    <h3>Skills</h3>
                        <article className='skillContainer'>
                            <p> JavaScript, ReactJS, Redux, Bootstrap, MongoDB, NodeJS, Sass, Cypress, jQuery npm</p>
                        </article>
                </aside>
            <section className='contact-wrapper'>
                <h3>Contact:</h3>
                 <div className='mail-contact'> 
                     <MdMailOutline className='icon'/>
                    <p ype='email' className='deets'>dloccx@gmail.com</p>  
                </div> 
            </section>
            </div>

            <h1>Projects</h1>
                <section className='projectsList'>
                    <div className='skillsMiniwrap'>
                        <h4>Skylines</h4>
                        <a href='https://skilines.netlify.com/'><img className='skylines' src={skylines} alt='skysite-thumbnail'/></a>
                        <p>React | JavaScript </p>
                    </div>
                    <div className='skillsMiniwrap'>
                        <h4>Shopper</h4>
                        <a href='https://2shop.netlify.com'><img  className='juxxed' src={juxxed} alt='storesite-thumbnail'/></a>
                        <p>React | JavaScript | Redux </p>
                        <a href='https://github.com/dtaylr/juxd'><p className='repo'>Git Repo</p></a>
                    </div>
                    <div className='skillsMiniwrap'>
                        <h4>Git Searcher</h4>
                        <a href='https://dev-info.netlify.com'><img className='gitSearch' src= {gitFinder} alt='gitsite-search-thumbnail'/></a>
                        <p>React | JavaScript | Node | Express </p>
                        <a href='https://github.com/dtaylr/finder'><p className='repo'>Git Repo</p></a>
                    </div>
                    <div className='skillsMiniwrap'>
                        <h4>Contact Logger</h4>
                        <a href='https://logger2.herokuapp.com/'><img className= 'contacter' src={contacter} alt='contact-site-thumbnail'/></a>
                        <p>React | JavaScript | Express | Node | MongoDB | JSON WebToken</p>
                        <a href='https://github.com/dtaylr/contact-keep'><p className='repo'>Git Repo</p></a>
                    </div>
                    <div className='skillsMiniwrap'>
                        <h4>Showroom</h4>
                        <a href='https://showroomv1.herokuapp.com/'><img className= 'showRoom' src={showRoom} alt='car-site-thumbnail'/></a>
                        <p>React | JavaScript | Node | Express | MongoDB</p>
                    </div>
                    <div className='skillsMiniwrap'>
                        <h4>Hunger 4 More</h4>
                        <a href='https://hunger4more.netlify.com'><img className= 'showRoom' src={h4m} alt='meal-site-thumbnail'/></a>
                        <p>React | JavaScript | mealDB API | Redux </p>
                    </div>
                </section>
            </article>
        </section>
        </div> 
    )
}

// Projects.propTypes = {

// }

export default Projects

import React from 'react'

const Footer = () => {
    return (
        <div id='footer-wrapper'>
            {/* <section className='footer'>
            <h5>Contact:</h5>
            <label htmlFor='email' value='Email' type='email'>
                Email: <p>gengarMoves@gmail.com</p>
            </label>
            <label htmlFor='phone' type='number'>
                Phone: <p>865-687-7553</p>
            </label>
        </section> */}
        </div>
    )
}

export default Footer

import React from 'react'

const Navbar = () => {
    return (
        <header id='header-wrapper'>
            <div className='header-content'>
                <section className='header'>
                    <div className='headerTitle'>
                        <h1>Welcome</h1>
                    </div>
                <div className='bio'>
                    <p>Thanks for visiting. I'm a front-end developer
                        using MERN stack. Checkout some of my work below
                        and feel free to get in touch at the contact provided.
                    </p>
                </div>
            </section>
            </div>
            <nav className='navBar'>
                {/* <article className='nav-links'>
                    <li>About</li>
                    <li>Contact</li>
                    <li>Projects</li>
                </article> */}
            </nav>
        </header>
    )
}

export default Navbar
